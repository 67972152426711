@import './varialble.scss';
@import './mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
body {
  margin: 0;
  // font-family: 'Source Sans Pro', sans-serif !important;
  font-family: 'Noto Sans JP', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Common ant component css */
.ant-layout {
  background-color: $app-bg-color !important;
  .ant-layout-content {
    z-index: 0;
  }
}

.ant-checkbox-wrapper {
  align-items: center !important;
  .ant-checkbox-inner {
    border-color: $sider-list-count !important;
    border-radius: 50% !important;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-inner:after {
    left: 28% !important;
    border-color: $sider-list-count !important;
    opacity: 1 !important;
    transform: rotate(45deg) scale(1) translate(-50%, -50%) !important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: $primary-background-color !important;
      background-color: $primary-background-color !important;
    }
    .ant-checkbox-inner:after {
      border-color: $white-bg-1 !important;
      left: 28%;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
    }
  }
}
/* END */

/* Header Design Start */

.page-header-wrapper {
  z-index: 1;
  height: 52px !important;
  width: 100%;
  position: fixed;
  background: $primary-background-color !important;
  padding: 0 !important;
  line-height: 52px !important;
  .page-header-content-wrapper {
    display: flex;
    .logo-title-wrapper {
      cursor: pointer;
      margin-left: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      .logo-icon {
        width: 35px;
        margin: 0 5px 10px 0;
        height: 35px;
      }
      .title {
        font-size: 20px;
        font-weight: 700;
        color: $white-bg-1;
        text-align: left;
      }
    }
    .right-menu-wrapper {
      display: flex;
      height: 52px;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: center;
      .help-menu-icon {
        margin-right: 10px;
        cursor: pointer;
        svg {
          font-size: 20px;
          color: $help-icon-color;
        }
      }
      .lang-select {
        margin-right: 20px;
      }
      .username-title {
        background-color: $background-color;
        font-size: 16px;
        font-weight: 700;
        padding: 0 20px;
        margin: 0;
      }
      .drawer-icon {
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }
}

/* END */

/* Sider Design Start */

.sider-layout-wrapper {
  .ant-layout-sider {
    min-height: 100vh;
    top: 0px !important;
    padding-top: 52px;
  }
  .sider-content-wrapper {
    .sider-collapse-content {
      cursor: pointer;
      margin-top: 45px;
      text-align: center;
      svg {
        font-size: 20px;
        color: $primary-background-color;
      }
      .ant-typography {
        font-size: 10px;
        color: $primary-background-color;
      }
    }
    .sider-title {
      display: flex;
      justify-content: space-between;
      padding: 18px;
      svg {
        margin-top: 5px;
        cursor: pointer;
      }
      .ant-typography {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .year-dropdown-wrapper {
      margin: 24px;
      .year-dropdown-title {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 400;
      }
    }
    .sider-search {
      margin: 24px;
      font-size: 16px;
      font-weight: 400;
      .ant-input-affix-wrapper {
        border-radius: 4px;
      }
    }
  }
}
.sider-content-wrapper {
  .sider-divider {
    margin-top: 0px;
  }
}
.sider-checklist-wrapper {
  margin: 24px;
  .sider-checklist-group {
    width: 100%;
    .sider-axis-tags-tooltip {
      cursor: pointer;
    }
    .sider-checklist-value {
      font-size: 16px;
      font-weight: 400;
    }
    .sider-checklist-value-count {
      font-size: 16px;
      font-weight: 400;
      color: $sider-list-count;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      @include indeterminateCheckbox;
    }
  }
  .sider-checklist-title {
    font-size: 16px;
    font-weight: 400;
  }
}
.sider-checklist-group {
  .sider-checklist-button {
    height: 50px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
  }
}
.sider-divider {
  border: 1px solid $divider-color;
}

/* END */

/* Project List Design Start */

.project-list-wrapper {
  .sorting-dropdown {
    margin-right: 10px;
  }
  .financial-card-wrapper {
    margin-bottom: 36px;

    .cart-project-accordion-wrapper {
      margin-top: 1px;
      border-radius: 8px;
      background-color: $white-bg-1;

      .ant-collapse-expand-icon {
        span {
          right: 30px;
          color: $primary-background-color;
        }
      }

      .cart-project-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .project-count-wrapper {
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            color: $gray-color;
          }
          .clear-cart-button {
            margin-left: 50px;
            cursor: pointer;
            color: $primary-background-color;
          }
          .ant-typography {
            margin: 0 10px;
            font-weight: 400;
          }
        }

        .project-cart-accordion-content {
          font-size: 12px;
          color: $primary-background-color;
        }
      }

      .ant-collapse-header {
        display: inherit;
        padding: 12px 52px;
      }

      .ant-collapse-content-box {
        padding: 0;
        border-top: 1px solid $divider-border-color;
      }

      .ant-table-cell-fix-left,
      .ant-table-cell-fix-right {
        z-index: 0;
      }

      .ant-table-ping-right {
        .ant-table-cell-fix-right-first::after {
          box-shadow: inset -12px 0 8px -8px $box-shadow-color;
        }
      }

      table {
        @include commonTable;
        tr:hover {
          cursor: pointer;
          td:nth-child(5),
          td:nth-child(6),
          td:nth-child(7) {
            .ant-typography {
              float: right;
            }
          }
          @include commonHyperLinkRow;
        }
        tr {
          th:nth-child(5),
          th:nth-child(6),
          th:nth-child(7) {
            text-align: right;
          }
          td {
            .ant-typography {
              margin-bottom: 1px;
            }
          }
          th:nth-child(6) {
            border-bottom: 2px solid $poss-redu-color;
          }
          th:nth-child(7) {
            border-bottom: 2px solid $funding-color;
          }
          td:nth-child(5),
          td:nth-child(6),
          td:nth-child(7) {
            text-align: right;
          }
          .add-cart-btn {
            width: 100px;
            @include commonButton;
          }
        }
      }
    }

    .financial-content-wrapper {
      padding: 0px 20px;
      border-radius: 8px;
      height: 200px;
      .total-amount-title {
        font-size: 13px;
        .ant-typography {
          color: $gray-color;
          margin-bottom: 10px;
        }
      }
      .amount-value {
        display: flex;
        margin-bottom: 10px;
        .ant-typography {
          margin: 0;
          span {
            font-size: 20px;
            font-weight: 700;
            margin: 0 5px;
          }
        }
        .ant-typography:nth-child(2) {
          font-size: 16px;
          margin: 20px 0px 0px 5px;
        }
      }
      .amount-detail {
        font-size: 12px;
        color: $gray-color;
        span:nth-child(2) {
          border-bottom: 2px solid $poss-redu-color;
          margin: 0 5px;
        }
        span:nth-child(4) {
          border-bottom: 2px solid $funding-color;
          margin-left: 5px;
        }
      }
      .total-req-amount-detail {
        font-size: 12px;
        color: $gray-color;
        margin-top: 10px;
      }
    }

    .financial-detail-wrapper {
      padding: 4px 30px;
      border-radius: 8px;
      height: 200px;
      .ant-card-body {
        height: 145px;
      }
      .financial-detail-value {
        display: flex;
        .ant-typography {
          margin: 0;
        }
        .ant-typography:last-child {
          font-size: 16px;
          margin: 10px 0px 0px 10px;
        }
        .ant-typography:first-child {
          font-size: 12px;
          color: $gray-color;
          margin-right: 10px;
        }
        .poss-redu-wrapper {
          display: flex;
          align-items: center;
          margin-right: 60px;
          .ant-typography:nth-child(2) {
            border-bottom: 2px solid $poss-redu-color;
            span {
              font-size: 20px;
              font-weight: 700;
              margin-left: 5px;
            }
          }
        }
        .poss-funding-wrapper {
          display: flex;
          align-items: center;
          .ant-typography:nth-child(2) {
            border-bottom: 2px solid $funding-color;
            span {
              font-size: 20px;
              font-weight: 700;
              margin-left: 5px;
            }
          }
        }
      }

      .financial-meter-wrapper {
        display: flex;
        margin-top: 24px;
        .poss-redu-meter {
          @include commonProgressMeter;
          border: 2px solid $poss-redu-color;
          border-radius: 4px 0px 0px 4px;
        }
        .poss-funding-meter {
          @include commonProgressMeter;
          margin-left: 2px;
          border: 2px solid $funding-color;
          border-radius: 0px 4px 4px 0px;
        }
      }
    }

    .calculation-content-wrapper {
      display: flex;
      align-items: center;
      .ant-typography {
        margin: 0;
      }
    }
  }

  .select-all-button {
    cursor: pointer;
    button {
      border-radius: 4px;
      border: 1px solid $divider-border-color;
      padding: 0 14px;
    }
  }

  .project-datatable-wrapper {
    overflow-x: auto;
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      z-index: 0;
    }
    .ant-table-ping-right {
      .ant-table-cell-fix-right-first::after {
        box-shadow: inset -12px 0 8px -8px $box-shadow-color;
      }
    }
    table {
      @include commonTable;
      tr:hover {
        cursor: pointer;
        td:nth-child(5),
        td:nth-child(6),
        td:nth-child(7) {
          .ant-typography {
            float: right;
          }
        }
        @include commonHyperLinkRow;
      }
      tr {
        th:nth-child(5),
        th:nth-child(7),
        th:nth-child(8) {
          text-align: right;
        }
        td {
          .ant-typography {
            margin-bottom: 1px;
          }
        }
        td:nth-child(5) {
          text-align: right;
        }
        th:nth-child(6),
        td:nth-child(6) {
          background-color: $app-bg-color;
          padding: 5px;
        }
        th:nth-child(7) {
          border-bottom: 2px solid $poss-redu-color;
        }
        td:nth-child(7) {
          text-align: right;
          font-weight: 700;
        }
        th:nth-child(8) {
          border-bottom: 2px solid $funding-color;
        }
        td:nth-child(8) {
          text-align: right;
          font-weight: 700;
        }
        .add-cart-btn {
          @include borderedButton;
          width: 100px;
        }
        .add-cart-btn.selected-item {
          @include commonButton;
        }
      }
      tr td:nth-child(7),
      tr td:nth-child(8) {
        color: $gray-color;
      }
      tr td:nth-child(7) .selected-poss-redu-item {
        color: $poss-redu-color;
      }
      tr td:nth-child(8) .selected-funding-item {
        color: $funding-color;
      }
    }
  }
}

/* END */

/* Table Modal Wrapper */
.modal-wrapper {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-footer {
      .ant-btn {
        @include commonButton;
      }
    }
  }
  .info-icon {
    color: $primary-background-color;
    margin-right: 10px;
    svg {
      font-size: 18px;
    }
  }

  .ant-modal-header {
    border-radius: 12px;
  }

  .modal-title-wrapper {
    .ant-radio-button-wrapper {
      border: none;
      background-color: $white-bg-1;
      margin-right: 10px;
      color: $primary-background-color;
      font-size: 16px;
    }
    .ant-radio-button-wrapper-checked {
      @include commonButton;
    }
    .ant-radio-button-wrapper:not(:first-child):before {
      content: none;
    }
  }
}

/* END */

/* Axis Tags Modals Design */
.axis-checkbox-group {
  height: 300px;
  overflow-x: auto;
}
.axis-tags-list-wrapper .axis-tags-content-wrapper {
  label {
    margin: 10px;
    font-size: 16px;
  }
}
/*  END */

/* Department Tags Modals Design */
.department-list-wrapper {
  display: flex;

  .department-content-wrapper {
    display: flex;
    flex-direction: column;
    .dept-checkbox-group {
      height: 260px;
      overflow-x: auto;
      .ant-checkbox-group {
        width: 100%;
      }
    }
    .selected-department {
      background-color: $background-color;
      .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        @include indeterminateCheckbox;
      }
    }
    .sider-checkbox-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
      }
      span {
        font-size: 16px;
      }
      .arrow-icon {
        width: 30px;
        border-radius: 100%;
        text-align: center;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 12px;
        }
      }
      .active {
        background: $active-arrow;
        color: $white-bg-1;
      }
    }
  }

  .department-section-content-wrapper {
    .section-checkbox-group {
      height: 260px;
      overflow-x: auto;
    }
    .ant-checkbox-group {
      width: 100%;
      .sider-checklist-value {
        width: 100%;
      }
    }
  }

  .department-content-wrapper:not(:last-child) {
    border-right: 2px solid $background-color;
  }

  .ant-checkbox-wrapper-checked {
    padding: 10px;
    background-color: $background-color;
  }

  .ant-checkbox-wrapper {
    padding: 10px;
    margin: 0px !important;
  }

  .ant-typography {
    text-align: center;
  }

  label {
    font-size: 16px;
  }
}
/* END */

/* Project Details Design */
.project-details-wrapper {
  margin: 10px 150px !important;
  border-radius: 8px !important;
  overflow-x: auto;

  .amount-unit-text {
    color: $gray-color;
    text-align: right;
  }

  .back-arrow {
    cursor: pointer;
    margin: 30px 0 10px;
    span {
      font-weight: 400;
      font-size: 30px;
      color: $gray-color;
      margin-right: 10px;
    }
  }

  .ant-divider {
    border: 1px solid $divider-border-color;
    margin: 40px 0;
  }
  .ant-card-body {
    padding: 0 40px 40px;
  }

  .project-name-wrapper {
    font-size: 24px;
    font-weight: 700;
  }

  .breadcrumb-content-wrapper {
    margin-top: 24px;
    font-size: 16px;
    color: $font-color;
    .city_name_title {
      color: $city-name-title;
    }
  }

  .project-detail-tag-wrapper {
    .project-detail-heading {
      margin: 20px 0 10px;
      @include projectDetailHeading;
    }
    .ant-tag {
      padding: 8px 16px;
      font-size: 17px;
      border: 2px solid $primary-background-color;
      border-radius: 4px;
      color: $primary-background-color;
    }
  }

  .total-project-table-wrapper {
    .project-detail-heading {
      margin: 0 0 10px;
      @include projectDetailHeading;
    }
    .total-project-table {
      table {
        @include commonTable;
        tr {
          th {
            white-space: normal;
            font-size: 14px;
            font-weight: 400;
          }
          td {
            text-align: right;
            font-weight: 700;
          }
          td:nth-child(5),
          td:nth-child(6) {
            color: $primary-background-color;
          }
        }
        border-collapse: separate;
        border-spacing: 5px;
      }
    }
  }

  .same-project-table-wrapper {
    .project-detail-heading {
      margin: -10px 0 10px;
      @include projectDetailHeading;
    }
    .same-project-table {
      table {
        @include commonTable;
        tr {
          th {
            font-size: 14px;
            font-weight: 400;
            border-right: 2px solid $white-bg-1;
            p {
              margin: 0;
            }
            .general-header {
              font-size: 12px;
              color: $gray-color;
            }
          }
          th:first-child {
            border-right: 2px solid $divider-border-color;
          }
          td {
            p {
              margin: 0;
            }
            .general-amount {
              font-size: 12px;
              color: $gray-color;
            }
            border-right: 2px solid $white-bg-1;
            .comment-popover-wrapper {
              @include commentPopoverContent;
            }
          }
          td:nth-child(3) {
            white-space: initial;
          }
          td:first-child {
            border-right: 2px solid $divider-border-color;
          }
          td:nth-child(2),
          td:nth-child(5),
          td:nth-child(6) {
            color: $primary-background-color;
            font-size: 16px;
            font-weight: 700;
          }

          td:nth-child(4),
          td:nth-child(5),
          td:nth-child(6),
          td:nth-child(7) {
            text-align: right;
          }
        }
        border-collapse: separate;
      }
    }
  }

  .related-project-table-wrapper {
    .project-detail-heading {
      margin: 0 0 10px;
      @include projectDetailHeading;
    }
    .related-project-table {
      table {
        @include commonTable;
        tr:hover {
          td {
            .related-project-column-data {
              width: fit-content;
              margin: 0;
              color: $hyper-link-color;
              cursor: pointer;
              border-bottom: 1px solid $hyper-link-color;
            }
          }
          td:nth-child(5),
          td:nth-child(6) {
            div {
              float: right;
            }
          }
        }
        tr {
          th {
            font-size: 14px;
            font-weight: 400;
            border-right: 2px solid $white-bg-1;
            p {
              margin: 0;
            }
            .general-header {
              font-size: 12px;
              color: $gray-color;
            }
          }
          th:nth-child(2) {
            border-right: 2px solid $divider-border-color;
          }
          td {
            p {
              margin: 0;
            }
            .general-amount {
              font-size: 12px;
              color: $gray-color;
            }
            .comment-popover-wrapper {
              @include commentPopoverContent;
            }
            border-right: 2px solid $white-bg-1;
          }
          td:nth-child(2) {
            border-right: 2px solid $divider-border-color;
          }
          td:nth-child(3),
          td:nth-child(5) {
            color: $primary-background-color;
            font-size: 16px;
            font-weight: 700;
          }
          td:nth-child(4) {
            pointer-events: none;
            div {
              pointer-events: all;
            }
          }
          td:nth-child(5),
          td:nth-child(6) {
            text-align: right;
          }
        }
        border-collapse: separate;
      }
    }
  }

  .project-desc-wrapper {
    font-size: 14px;
    margin-top: 30px;
  }

  .tag-version-wrapper {
    font-size: 16px;
  }

  .detail-table-wrapper {
    table {
      border-collapse: separate;
      border-spacing: 5px;
      @include commonTable;
    }
  }

  .ins-wrapper {
    margin-top: 40px;
  }
}
/* END */

/* Authentication Design Start */
.login-wrapper {
  background-color: $app-bg-color;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form-container {
    width: 50%;
    border-radius: 8px;

    .ant-card-head-title {
      font-size: 20px;
    }
  }
  .login-footer-btn-wrapper {
    button {
      width: 120px;
      padding: 0 15px;
      font-size: 16px;
      background-color: $auth-button-color;
      border-color: $auth-button-color;
    }
    .ant-typography {
      font-size: 16px;
      color: $auth-button-color;
      cursor: pointer;
    }
  }
}
/* END */

/* Sider Popover Design Start */
.account-popover {
  z-index: 0 !important;
  padding-top: 5px !important;
  .ant-popover-arrow {
    right: 10px !important;
  }
  .ant-popover-content {
    right: 5px !important;

    .ant-popover-arrow {
      span::before {
        background-color: $primary-background-color;
      }
    }

    .ant-popover-title {
      background-color: $primary-background-color;
      color: $white-bg-1;
      padding: 10px 15px;
      border-radius: 8px 8px 0 0;
    }

    .ant-popover-inner {
      border-radius: 8px;
    }

    .logout-content-wrapper {
      display: flex;
      cursor: pointer;
      .logout-icon {
        svg {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
      .logout-text {
        margin-top: -2px;
      }
    }
  }
}
.drawer-icon {
  width: 100%;
}
/* END */

/* Toast Notification Design Start */
.Toastify__toast-body {
  div {
    font-size: 15px;
    text-transform: capitalize;
  }
}
/* END */

/* Assessment Comment Popover Design Start */
.comment-popover {
  width: 25%;
  .ant-popover-content {
    .ant-popover-inner-content {
      white-space: pre-wrap;
      word-wrap: initial;
    }
  }
}
/* END */

/* Not Verified Page Design Start */
.not-verified-content-wrapper {
  background-color: $app-bg-color;
  width: 100%;
  height: 100vh;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logout-btn {
    width: 120px;
    padding: 0 15px;
    font-size: 16px;
    background-color: $auth-button-color;
    border-color: $auth-button-color;
    color: $white-bg-1;
  }
  .logout-btn:hover {
    background-color: $auth-button-color;
    border-color: $auth-button-color;
    color: $white-bg-1;
  }
}
/* END */

/* Password Check Tooltip Design Start */
.password-check-tooltip {
  max-width: 310px !important;
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      span {
        background-color: $white-bg-1;
      }
      span:before {
        content: none;
      }
    }
    .ant-tooltip-inner {
      background-color: $white-bg-1;
      border-radius: 8px;
      color: $font-color;
      font-size: 14px;
      svg {
        font-size: 20px;
        margin-right: 5px;
      }
      .valid-icon {
        svg {
          color: $valid-password-color;
        }
      }
      li.valid {
        span {
          color: $valid-password-color;
        }
      }
    }
  }
}
/* END */
