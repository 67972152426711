$white-bg-1: #FFFFFF;
$background-color: #CEDFE0;
$primary-background-color: #16828b;
$table-cell-color: #F5FAFA;
$divider-color: #BAC4C5;
$sider-list-count: #BDBDBD;
$font-color: #292929;
$divider-border-color: #E1EAEB;
$app-bg-color: #F5F7F7;
$poss-redu-color: #2980B9;
$funding-color: #27AE60;
$gray-color: #858585;
$hyper-link-color: #1890FF;
$active-arrow: #81A1A3;
$help-icon-color: #A5A5A5;
$auth-button-color: #10C6AA;
$box-shadow-color: #00000026;
$valid-password-color: #85DD5A;
$city-name-title: #f10000